import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { selectTransactionTimestamp } from '../store/reportSlice';

const TextContent = ({ payload }) => {
  const timestamp = useSelector(selectTransactionTimestamp);
  // const { rawText, timestamp } = text;
  // const sig = signature && signature.signature;
  // const key = signature && signature.publicKey;
  return (
    <div className="text-card">
      <div className="inner">
        {!payload.text.includes('###') && <h3>Eternal Transaction on {timestamp}</h3>}
        <ReactMarkdown source={payload.text} />
      </div>
    </div>
  );
};

export default TextContent;
