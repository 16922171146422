import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { LayoutBlock, Container, Column, Row } from '@dragonchain-dev/dragon-kit';
import { useEffectOnce } from '../utils/useEffectCustom';
import {
  getReport,
  clearReport,
  clearTransactionId,
  selectTransaction,
  selectTransactionType,
} from '../store/reportSlice';
import BackIcon from '../assets/icons/back.svg';
import { PageLayout, Download, Report, TweetContent } from '../components/_index';
import TextContent from '../components/TextContent';

const Transaction = ({ transactionId }) => {
  const dispatch = useDispatch();
  const { payload } = useSelector(selectTransaction);
  const transactionType = useSelector(selectTransactionType);

  useEffectOnce(() => {
    dispatch(getReport({ transactionId }));
    dispatch(clearTransactionId());
  });

  return (
    <PageLayout>
      <LayoutBlock>
        <Container>
          <Row>
            <Column span="12">
              <div className="inner">
                <div className="report">
                  <div className="inner">
                    <div className="report-nav">
                      <div className="inner">
                        <button
                          type="button"
                          className="back-button"
                          onClick={() => {
                            dispatch(clearReport());
                            navigate('/');
                          }}
                        >
                          <BackIcon />
                        </button>
                        <div className="txn">
                          <div className="txn-label">TXN</div>
                          <div className="txn-value">{transactionId}</div>
                        </div>
                        <div className="actions">
                          <div className="inner">
                            <Download />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Report>
                      <div className="report-content">
                        {transactionType === 'tweet' && <TweetContent payload={payload} />}
                        {transactionType === 'ledger' && <TextContent payload={payload} />}
                      </div>
                    </Report>
                  </div>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Transaction;
